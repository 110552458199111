import {
	locale as localeStore,
	country,
	type SupportedCountries,
	type SupportedLocales,
} from '@store/user';
import { init as i18nextInit } from '@utils/i18n';

type Props = {
	locale: SupportedLocales;
	countryCode: SupportedCountries;
};
export function LocaleSetter({ locale, countryCode }: Props) {
	i18nextInit(locale);
	localeStore.set(locale);
	country.set(countryCode);
	return null;
}
